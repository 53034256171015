import Button, { ButtonProps } from '~/components/interactive/Button';
import useLoadingClick from '~/components/hooks/useLoadingClick';
import IconSolidLoading from '~/components/icons/streamline/solid/IconSolidLoading';
import { useEffect, useState } from 'react';
import IconCheck from '~/components/icons/streamline/line/IconCheck';

export default function LoadingButton({ variant, ...props }: ButtonProps) {
  const [loading, onClickWrapper, success] = useLoadingClick();
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    if (!isSuccess) {
      if (success) {
        setSuccess(true);

        return;
      }
    }

    const timeout = setTimeout(() => {
      setSuccess(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [success, isSuccess]);

  return (
    <Button
      {...props}
      variant={isSuccess ? 'success' : variant}
      onClick={onClickWrapper((e) => {
        if (props.onClick) {
          return props.onClick(e);
        }
      })}
      disabled={loading ? true : props.disabled}
      icon={isSuccess ? <IconCheck /> : loading ? <IconSolidLoading /> : props.icon}
    />
  );
}
